<template>
  <div class="searchNew">
    <div class="search_Fixed flex-container">
      <svg
        t="1624524317890"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="1183"
        width="24"
        height="24"
        @click="returnPage"
      >
        <path
          d="M454.4 211.2a21.397333 21.397333 0 0 1 0 30.165333L164.565333 531.2a21.397333 21.397333 0 0 1-30.165333 0 21.397333 21.397333 0 0 1 0-30.165333L424.234667 211.2a21.397333 21.397333 0 0 1 30.165333 0z"
          fill="#222222"
          p-id="1184"
        ></path>
        <path
          d="M454.4 821.333333a21.397333 21.397333 0 0 1-30.165333 0L134.4 531.498667a21.397333 21.397333 0 0 1 0-30.165334 21.397333 21.397333 0 0 1 30.165333 0L454.4 791.168a21.397333 21.397333 0 0 1 0 30.165333z"
          fill="#222222"
          p-id="1185"
        ></path>
        <path
          d="M874.666667 533.333333H192c-11.733333 0-21.333333-9.6-21.333333-21.333333s9.6-21.333333 21.333333-21.333333h682.666667c11.733333 0 21.333333 9.6 21.333333 21.333333s-9.6 21.333333-21.333333 21.333333z"
          fill="#222222"
          p-id="1186"
        ></path>
      </svg>

      <van-search
        class="flex1"
        v-model="keyword"
        show-action
        placeholder="请输入搜索关键词"
        @search="getGoodsList()"
        autofocus
      >
        <template #action>
          <div @click="getGoodsList()">搜索</div>
        </template>
      </van-search>
    </div>
    <div class="divSolid" ref="solid"><!-- 占位符 --></div>
    <div class="search_scroll" :style="{ height: scrollHeight + 'px' }">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        class="list"
      >
        <van-card
          lazy-load
          v-for="(item, index) in list"
          :key="index"
          :price="item.goods_price"
          :origin-price="item.goods_marketprice"
          :title="item.goods_name"
          :thumb="item.goodsimage_full_url"
          @click="goto(item.goods_id)"
        />
      </van-list>
    </div>
  </div>
</template>

<script>
import { Search, List, Card, Toast } from "vant";

import { post } from "@get/http";
export default {
  props: {
    kw: {
      type: String,
      required: true,
    },
    boo: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    vanSearch: Search,
    vanList: List,
    vanCard: Card,
  },
  data() {
    return {
      keyword: "",
      list: [],

      loading: false,
      finished: false,
      params: {
        store_id: process.env.VUE_APP_STORE,
        gc_id: "",
        is_exchange: 0,
        is_hot: 0,
        activity: null,
        sort_key: "", // 排序键
        sort_order: "", // 排序键, //排序值
        page: 1,
      },
      hotSearch: [],
      searchShow: true,
      scrollHeight: 0,
    };
  },
  mounted() {
    this.scrollHeight =
      window.screen.height - this.$refs.solid.offsetHeight - 10;
    // this.scrollHeight = this.$refs.solid.offsetHeight;
  },
  created() {
    this.keyword = this.kw;
    this.getGoodsList();
  },
  watch: {
    boo() {
      if (this.boo) {
        this.keyword = this.kw;
      }
      this.getGoodsList();
    },
  },

  methods: {
    returnPage() {
      this.$emit("update:boo", false);
    },
    onLoad() {
      this.loading = false;
      this.finished = false;
    },
    goto(goods_id) {
      this.$router.push({
        name: "HomeGoodsdetail",
        query: {
          goods_id,
        },
      });
    },

    isNull(str) {
      if (str == "") return true;
      var regu = "^[ ]+$";
      var re = new RegExp(regu);
      return re.test(str);
    },
    async getGoodsList() {
      if (this.isNull(this.keyword)) {
        Toast("请输入关键词");
        return false;
      }
      this.loading = true;
      this.finished = true;
      this.searchShow = false;
      this.$store.dispatch("doSetSearchHistory", this.keyword);
      let { goods_list } = await post("/lw.Store/store_goods_p", {
        data: {
          ...this.params,
          keyword: this.keyword,
        },
      });
      this.list = goods_list;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.searchNew {
  max-height: 100%;
  overflow: hidden;
  // padding-bottom: 3rem;
}
.search_Fixed {
  // pointer-events: none;
  overflow: hidden;
  width: 100%;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: #f2f2f2;
  svg {
    padding: 0 rem(20) 0 rem(36);
  }
  .flex1 {
    background: #f2f2f2;
  }
}
::v-deep {
  .van-search__action {
    div {
      font-size: rem(30);
      padding: 0 rem(30);
      background: linear-gradient(to right, #fe9500, #ff5b03);
      color: #fff;
      height: rem(68);
      border-radius: rem(34);
    }
  }
}

.search_scroll {
  height: rem(1100);
  overflow-y: scroll;
}
.list {
  margin-bottom: rem(150);
}
.divSolid {
  height: rem(110);
}
</style>
