<template>
  <div class="searchNew">
    <div class="search_Fixed flex-container">
      <svg
        t="1624524317890"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="1183"
        width="24"
        height="24"
        @click="returnPage"
      >
        <path
          d="M454.4 211.2a21.397333 21.397333 0 0 1 0 30.165333L164.565333 531.2a21.397333 21.397333 0 0 1-30.165333 0 21.397333 21.397333 0 0 1 0-30.165333L424.234667 211.2a21.397333 21.397333 0 0 1 30.165333 0z"
          fill="#222222"
          p-id="1184"
        ></path>
        <path
          d="M454.4 821.333333a21.397333 21.397333 0 0 1-30.165333 0L134.4 531.498667a21.397333 21.397333 0 0 1 0-30.165334 21.397333 21.397333 0 0 1 30.165333 0L454.4 791.168a21.397333 21.397333 0 0 1 0 30.165333z"
          fill="#222222"
          p-id="1185"
        ></path>
        <path
          d="M874.666667 533.333333H192c-11.733333 0-21.333333-9.6-21.333333-21.333333s9.6-21.333333 21.333333-21.333333h682.666667c11.733333 0 21.333333 9.6 21.333333 21.333333s-9.6 21.333333-21.333333 21.333333z"
          fill="#222222"
          p-id="1186"
        ></path>
      </svg>

      <van-search
        class="flex1"
        v-model="keyword"
        show-action
        placeholder="请输入搜索关键词"
        @search="getGoodsList()"
        autofocus
      >
        <template #action>
          <div @click="getGoodsList()">搜索</div>
        </template>
      </van-search>
    </div>

    <div class="divSolid"><!-- 占位符 --></div>
    <div
      class="search_result_list"
      v-if="searchShow && searchHistory && searchHistory.length != 0"
    >
      <div class="search_h1 ">
        <h1>搜索历史</h1>
        <img
          class="del"
          src="@image/order/order_del.png"
          @click="diaogDelHistory"
        />
      </div>
      <ul class="search_history_ul">
        <li
          v-for="(item, index) in searchHistory"
          :key="index"
          @click="getGoodsList(item)"
        >
          {{ item }}
        </li>
      </ul>
    </div>
    <div
      class="search_result_list"
      v-if="searchShow && hotSearch && hotSearch.length != 0"
    >
      <div class="search_h1">
        <h1>大家都在搜</h1>
      </div>
      <ul class="search_history_ul">
        <li
          v-for="(item, index) in hotSearch"
          :key="index"
          @click="getGoodsList(item.key)"
        >
          {{ item.key }}
        </li>
      </ul>
    </div>

    <div class="search_scroll">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        class="list"
      >
      </van-list>
    </div>

    <!-- 搜索结果 -->
    <van-popup
      get-container="#app"
      v-model="SearchResultShow"
      position="right"
      lock-scroll
      close-on-popstate
      :overlay="false"
      :style="{ height: '100%', width: '100%', background: '#f8f8f8' }"
    >
      <oSearchResult :boo.sync="SearchResultShow" :kw="keyword"></oSearchResult>
    </van-popup>
  </div>
</template>

<script>
import { Search, List, Card, Toast, Dialog, Popup } from "vant";
import { post } from "@get/http";
import { mapState } from "vuex";
import oSearchResult from "./result";
export default {
  components: {
    vanSearch: Search,
    vanList: List,
    vanCard: Card,
    vanPopup: Popup,
    oSearchResult,
  },
  data() {
    return {
      SearchResultShow: false,
      keyword: "",
      hotSearch: [],
      searchShow: true,
      loading: false,
      finished: false,
    };
  },
  computed: {
    ...mapState({
      searchHistory: (state) => state.search.history,
    }),
  },
  created() {
    this.getHotData();
  },

  methods: {
    onLoad() {
      this.loading = false;
      this.finished = false;
    },
    diaogDelHistory() {
      Dialog.confirm({
        title: "警告",
        message: "确定要删除历史搜索记录吗？",
      }).then(() => {
        this.$store.dispatch("doSetSearchHistory", []);
      });
    },
    async getHotData(val) {
      let data = await post("/lw.Store/getHotSearch", {
        data: {
          limit: 15,
        },
      });
      this.hotSearch = data;
    },
    returnPage() {
      this.$emit("update:boo", false);
      this.$emit("update:endPage", false);
    },

    isNull(str) {
      if (str == "") return true;
      var regu = "^[ ]+$";
      var re = new RegExp(regu);
      return re.test(str);
    },
    async getGoodsList(key) {
      if (key) {
        this.keyword = key;
      }
      if (this.isNull(this.keyword)) {
        Toast("请输入关键词");
        return false;
      }
      this.SearchResultShow = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.searchNew {
  max-height: 100%;
  overflow: hidden;
  // padding-bottom: 3rem;
}
.search_Fixed {
  // pointer-events: none;
  overflow: hidden;
  width: 100%;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: #f2f2f2;
  svg {
    padding: 0 rem(20) 0 rem(36);
  }
  .flex1 {
    background: #f2f2f2;
  }
}
::v-deep {
  .van-search__action {
    div {
      font-size: rem(30);
      padding: 0 rem(30);
      background: linear-gradient(
        90deg,
        rgb(255, 102, 102) 0%,
        rgb(255, 72, 72) 100%
      );
      color: #fff;
      height: rem(68);
      border-radius: rem(34);
    }
  }
}

.divSolid {
  height: rem(110);
}
.search_scroll {
  height: rem(1300);
  overflow-y: scroll;
}

.search_h1 {
  padding: rem(34) 0;
  margin: 0 rem(34);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  h1 {
    flex: 1;
    color: #121212;
    font-size: rem(34);
    font-weight: bold;
  }
  .del {
    display: block;
    width: rem(27);
    height: rem(30);
  }
}

.search_history_ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0 rem(14);
  li {
    margin: 0 rem(20);
    color: #121212;
    height: rem(56);
    line-height: rem(56);
    text-align: center;
    border-radius: rem(56/2);
    background: #fff;
    font-size: rem(26);
    padding: rem(6) rem(22);
    margin-bottom: rem(20);
  }
}
</style>
